import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const VolvoSafety = ({ data, images }) => {
	return (
		<Container>
			<Wrapper>
				<Content>
					<Title>{data.title}</Title>
					<SubTitle>{data.subtitle}</SubTitle>
					<Text>{data.text}</Text>
				</Content>
				<Image data-image="adjust">
					<SkImage {...images.find((image) => image.name === data.adjust).image} />
				</Image>
				<Image data-image="card">
					<SkImage {...images.find((image) => image.name === data.card).image} />
				</Image>
				<Image data-image="notes">
					<SkImage {...images.find((image) => image.name === data.notes).image} />
				</Image>
				<Image data-image="folder">
					<SkImage {...images.find((image) => image.name === data.folder).image} />
				</Image>
				<Image data-image="shield">
					<SkImage {...images.find((image) => image.name === data.shield).image} />
				</Image>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	padding: 40px 16px;
	${media.md`
		padding: 60px 24px;
	`}
	${media.xl`
		padding: 80px 40px;
	`}
`;

const Wrapper = styled.div`
	position: relative;
	background: ${(p) => p.theme.colors.superlightGray};
	padding: 170px 16px;
	${media.md`
		padding: 315px 60px;
	`}
	${media.xl`
		padding: 250px 0;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.xl`
        width: 775px;
	    margin: 0 auto;
        gap: 24px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	color: ${(p) => p.theme.colors.bluePrimary};
	line-height: 28px;
	text-align: center;
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.dark};
	text-align: center;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.dark};
	text-align: center;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Image = styled.div`
	width: 80px;
	height: auto;
	position: absolute;
	${media.md`
		width: 160px;
		height: auto;
    `}
	&[data-image='adjust'] {
		top: 50px;
		left: 16px;
		${media.md`
            top: 130px;
		    left: 24px;
        `}
		${media.xl`
            top: 115px;
		    left: 100px;
        `}
	}
	&[data-image='card'] {
		top: 7px;
		left: 55%;
		transform: translate(-45%);
		${media.md`
            top: 40px;
		    left: 50%;
		    transform: translate(-50%);
        `}
	}
	&[data-image='notes'] {
		top: 75px;
		right: 11px;
		${media.md`
            top: 92px;
		    right: 32px;
        `}
		${media.xl`
            top: 152px;
		    right: 88px;
        `}
	}
	&[data-image='folder'] {
		bottom: 30px;
		right: 37px;
		${media.md`
            bottom: 80px;
		    right: 60px;
        `}
		${media.xl`
            bottom: 88px;
		    right: 160px;
        `}
	}
	&[data-image='shield'] {
		bottom: 50px;
		left: 36px;
		${media.md`
            bottom: 24px;
		    left: 100px;
        `}
		${media.xl`
            bottom: 60px;
		    left: 200px;
        `}
	}
`;
