import React from 'react';
import styled from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const VolvoDetected = ({ data, images }) => {
	return (
		<Wrapper>
			<SkImage {...images.find((image) => image.name === data.image).image} />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	height: fit-content;
	margin: 15px 0 0 0;
	${media.md`
		padding: 40px 0 0 0;
	`}
	${media.xl`
		padding: 80px 0 0 0;
	`}
	> img {
		width: 100%;
		height: auto;
	}
`;
