import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { RemixIcon } from '../shared/remix-icon';

export const VolvoDiagnostics = ({ data }) => {
	return (
		<Container>
			<Wrapper>
				<Title>{data.title}</Title>
				<Items>
					{data.items.map((item) => (
						<Item key={item.text}>
							<Icon>
								<RemixIcon className={item.icon} />
							</Icon>
							<Text>{item.text}</Text>
							<ItemTitle>{item.title}</ItemTitle>
						</Item>
					))}
				</Items>
			</Wrapper>
		</Container>
	);
};

const Wrapper = styled.div`
	padding: 80px 16px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		padding: 120px 24px;
        gap: 40px;
	`}
	${media.xl`
		padding: 250px 80px;
        gap: 60px;
	`}
	${media.xxl`
		padding: 250px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Container = styled.div`
	background: ${(p) => p.theme.colors.superlightGray};
`;

const Title = styled.p`
	color: ${(p) => p.theme.colors.bluePrimary};
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t6};
		`}
		line-height: 40px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	${media.md`
		flex-direction: row;
	`}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	${media.md`
		width: 50%;
	`}
`;

const ItemTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h3};
	`}
	line-height: 48px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.bluePrimary};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.bluePrimary};
	margin-bottom: 8px;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
        margin-bottom: 24px;
	`}
	${media.xl`
		margin-bottom: 40px;
	`}
`;

const Icon = styled.div`
	color: ${(p) => p.theme.colors.bluePrimary};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	margin-bottom: 16px;
`;
