import React from 'react';
import { navigate } from 'gatsby';

import { PopupProvider } from '@context/popup-context';
import { TemplateProps } from '@templates/types';

import { GetInTouch } from '../../components/get-in-touch';
import { Popup } from '../../components/popup';
import { ReviewSection } from '../../components/review';
import { Seo } from '../../components/shared/seo';
import { VolvoAbout } from '../../components/volvo/volvo-about';
import { VolvoAchievement } from '../../components/volvo/volvo-achievement';
import { VolvoDetected } from '../../components/volvo/volvo-detected';
import { VolvoDiagnostics } from '../../components/volvo/volvo-diagnostics';
import { VolvoFeatures } from '../../components/volvo/volvo-features';
import { VolvoHero } from '../../components/volvo/volvo-hero';
import { VolvoHowItWorks } from '../../components/volvo/volvo-how-it-works';
import { VolvoMetrics } from '../../components/volvo/volvo-metrics';
import { VolvoOffice } from '../../components/volvo/volvo-office';
import { VolvoPartner } from '../../components/volvo/volvo-partner';
import { VolvoRequirements } from '../../components/volvo/volvo-requirements';
import { VolvoSafety } from '../../components/volvo/volvo-safety';
import { VolvoShweden } from '../../components/volvo/volvo-shweden';
import { VolvoTarget } from '../../components/volvo/volvo-target';
import { VolvoWhyUs } from '../../components/volvo/volvo-why';
import MainLayout from '../../layouts/main-layout';

export const VolvoTemplate = (props: TemplateProps) => {
	const { page, menu, header, footer, cookieBanner, theme, ctaPopup, seo } = props.pageContext;
	const attributes = page.attributes;

	if (!page) {
		return navigate('/404');
	}
	const { GlobalMeta } = seo.data.attributes;

	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="hidden"
			theme={theme}
			popup={ctaPopup}
		>
			<Seo meta={page.attributes.meta} globalMeta={GlobalMeta} url={page.attributes.url} />
			{attributes && (
				<PopupProvider>
					{ctaPopup?.data && (
						<Popup
							form={ctaPopup.data.attributes.form}
							formResponse={ctaPopup.data.attributes.formResponse}
						/>
					)}
					<VolvoHero
						data={attributes.content.hero}
						images={attributes.images}
						path={attributes.url}
					/>
					<VolvoMetrics data={attributes.content.section_1} />
					<VolvoAbout data={attributes.content.section_2} images={attributes.images} />
					<VolvoWhyUs data={attributes.content.section_3} path={attributes.url} />
					<VolvoHowItWorks data={attributes.content.section_4} images={attributes.images} />
					<VolvoRequirements data={attributes.content.section_5} images={attributes.images} />
					<VolvoDetected data={attributes.content.section_6} images={attributes.images} />
					<VolvoDiagnostics data={attributes.content.section_7} />
					<VolvoTarget data={attributes.content.section_8} images={attributes.images} />
					<VolvoFeatures data={attributes.content.section_9} />
					<VolvoSafety data={attributes.content.section_10} images={attributes.images} />
					<VolvoOffice data={attributes.content.section_11} images={attributes.images} />
					<VolvoAchievement data={attributes.content.section_12} images={attributes.images} />
					<VolvoShweden data={attributes.content.section_13} images={attributes.images} />
					<VolvoPartner data={attributes.content.section_14} />
					{attributes?.sections?.data[0] && <ReviewSection data={attributes.sections.data[0]} />}
					<GetInTouch images={attributes.images} />
				</PopupProvider>
			)}
		</MainLayout>
	);
};
