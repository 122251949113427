import React from 'react';
import styled, { css } from 'styled-components';

import { useMatchMedia, useTheme } from '@hooks';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const VolvoAchievement = ({ data, images }) => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: ${theme.breakpoints.xl}px)`);
	return (
		<Wrapper>
			<Header>
				<Title>{data.title}</Title>
				<Description>{data.subtitle}</Description>
			</Header>
			<Content>
				{isDesktop ? (
					<>
						<Square>
							<TextContent>
								<Item>
									<ItemTitle>{data.before.title}</ItemTitle>
									<ItemText>{data.before.text}</ItemText>
								</Item>
								<Item>
									<ItemTitle>{data.process.title}</ItemTitle>
									<ItemText>{data.process.text}</ItemText>
								</Item>
							</TextContent>
							<Images>
								<First>
									<Image>
										<SkImage
											{...images.find((image) => image.name === data.images.graphite).image}
										/>
									</Image>
								</First>
								<SkImage {...images.find((image) => image.name === 'gray_arrow').image} />
								<Second>
									<Image>
										<SkImage
											{...images.find((image) => image.name === data.images.grafana).image}
										/>
									</Image>
									<Plus>+</Plus>
									<Image>
										<SkImage
											{...images.find((image) => image.name === data.images.prometheus).image}
										/>
									</Image>
								</Second>
							</Images>
						</Square>
						<Item>
							<ItemTitle> {data.results.title}</ItemTitle>
							{data.results.items.map((item, index) => (
								<Paragraph key={item}>
									<Number>0{index + 1}</Number>
									<ItemText>{item}</ItemText>
								</Paragraph>
							))}
						</Item>
					</>
				) : (
					<>
						<Item>
							<ItemTitle>{data.before.title}</ItemTitle>
							<ItemText>{data.before.text}</ItemText>
						</Item>
						<Item>
							<ItemTitle>{data.process.title}</ItemTitle>
							<ItemText>{data.process.text}</ItemText>
						</Item>
						<Item>
							<ItemTitle> {data.results.title}</ItemTitle>
							{data.results.items.map((item, index) => (
								<Paragraph key={item}>
									<Number>0{index + 1}</Number>
									<ItemText>{item}</ItemText>
								</Paragraph>
							))}
						</Item>
						<Images>
							<First>
								<Image>
									<SkImage {...images.find((image) => image.name === data.images.graphite).image} />
								</Image>
							</First>
							<SkImage {...images.find((image) => image.name === 'gray_arrow').image} />
							<Second>
								<Image>
									<SkImage {...images.find((image) => image.name === data.images.grafana).image} />
								</Image>
								<Plus>+</Plus>
								<Image>
									<SkImage
										{...images.find((image) => image.name === data.images.prometheus).image}
									/>
								</Image>
							</Second>
						</Images>
					</>
				)}
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 60px 16px;
	display: flex;
	flex-direction: column;
	gap: 48px;
	${media.md`
		padding: 60px 24px;
        gap: 80px;
	`}
	${media.xl`
		padding: 80px 80px;
        gap: 60px;
	`}
	${media.xxl`
		padding: 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Header = styled.div`
	margin-bottom: 24px;
	${media.md`
        margin-bottom: 40px;
    `}
	${media.xl`
        margin-bottom: 60px;
        display: flex;
        gap: 40px;
        flex-direction: row;
        align-items: end;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.bluePrimary};
	letter-spacing: 0.01em;
	margin-bottom: 24px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
                line-height:48px;
	`}
	${media.xl`
        margin-bottom: 0;
        width: 60%;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
                line-height: 72px;
	`}
`;

const Description = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
                line-height: 22px;
	`}
	${media.xl`
        max-width: 40%;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
                line-height: 24px;
    `}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.xl`
        flex-direction: row;
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	${media.md`
       gap: 16px;
	`}
	${media.xl`
       gap: 24px;
	`}
`;

const ItemTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
                line-height:32px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
                line-height: 40px;
	`}
`;

const ItemText = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
                line-height:22px;
	`}
`;

const Number = styled.div`
	font-weight: 600;
	color: ${(p) => p.theme.colors.superlightGray};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h2};
	`}
	line-height: 72px;
	letter-spacing: 0.01em;
	height: 48px;
	overflow: hidden;
`;

const Images = styled.div`
	padding: 40px 45px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: 100%;
	background: ${(p) => p.theme.colors.superlightGray};
	align-items: center;
	${media.md`
        padding: 40px 92px;
    `}
	${media.xl`
        padding: 40px 115px;
        justify-content: center;
    `}
    ${media.xxl`
        padding: 100px 92px;
        flex-direction: row;
        gap: 40px;
        > img {
            transform: rotate(-90deg);
        }
    `}
`;

const First = styled.div`
	padding: 24px;
	height: 88px;
	background: ${(p) => p.theme.colors.gray1};
	border-radius: 8px;
	margin: 0 15px 18px 15px;
	display: flex;
	align-items: center;
	margin-bottom: 18px;
	position: relative;
	&::before {
		content: ' ';
		position: absolute;
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 18px 14px 0 14px;
		border-color: ${(p) => p.theme.colors.gray1} transparent transparent transparent;
		bottom: -17px;
		left: 50%;
		transform: translate(-50%);
		${media.xxl`
            border-color: transparent transparent transparent #EBEBEB;
            border-width: 14px 0 14px 18px;
            left: auto;
            right: -17px;
            top: 50%;
		    transform: translateY(-50%);
        `}
	}
	${media.md`
        margin: 0 165px 18px 165px;
    `}
	${media.xxl`
        margin: 0 18px 0 0;
        width: 205px;
        height: 105px;
    `}
`;

const Second = styled.div`
	background: ${(p) => p.theme.colors.gray1};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	align-items: center;
	${media.md`
        flex-direction: row;
        max-height: 105px;
    `}
	${media.xl`
        gap: 45px;
    `}
    ${media.xxl`
        height: 105px;
    `}
`;

const Image = styled.div`
	width: 100%;
	> img {
		width: 100%;
	}
`;

const Plus = styled.div`
	font-weight: 100;
	font-size: 35px;
	line-height: 14px;
	color: ${(p) => p.theme.colors.bluePrimary};
	padding: 5px;
`;

const Square = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	min-width: 66.666%;
`;

const TextContent = styled.div`
	display: flex;
	flex-direction: row;
	gap: 24px;
`;

const Paragraph = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
