import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMediaQuery } from '@hooks';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const VolvoHowItWorks = ({ data, images }) => {
	const isMobile = useMediaQuery(`(max-width: 767px`);
	const isTablet = useMediaQuery(`(min-width: 768px`);
	const isDesktop = useMediaQuery(`(min-width: 1200px`);
	const isLarge = useMediaQuery(`(min-width: 1920px`);
	const [size, setSize] = useState('desktop');
	useEffect(() => {
		isMobile && setSize('mobile');
		isTablet && setSize('tablet');
		isDesktop && setSize('desktop');
		isLarge && setSize('large');
	}, [isMobile, isTablet, isDesktop, isLarge, size]);
	return (
		<Container>
			<Wrapper>
				<Title>{data.title}</Title>
				<Content>
					<Section>
						<FirstTruck>
							<SkImage {...images.find((image) => image.name === data.first_truck).image} />
						</FirstTruck>
						<Text>{data.first_text}</Text>
					</Section>
					<Section>
						<Arrow>
							<SkImage {...images.find((image) => image.name === data.arrow).image} />
						</Arrow>
						<MiddleTruck>
							<SkImage {...images.find((image) => image.name === data.midle_server_truck).image} />
						</MiddleTruck>
						<Text>{data.middle_text}</Text>
					</Section>
					<TabletSection />
					<Section>
						<Arrow data-last={'true'}>
							<SkImage {...images.find((image) => image.name === data.arrow).image} />
						</Arrow>
						<LastTruck>
							<SkImage {...images.find((image) => image.name === data.last_truck).image} />
						</LastTruck>
						<Text>{data.last_text}</Text>
					</Section>
				</Content>
				<Partner>
					<SkImage {...images.find((image) => image.name === data.partner).image} />
				</Partner>
				<Technologies>
					<SubTitle>{data.second_title}</SubTitle>
					<TruckWrapper>
						<SkImage {...images.find((image) => image.name === `truck_${size}`).image} />
					</TruckWrapper>
				</Technologies>
			</Wrapper>
		</Container>
	);
};

const Wrapper = styled.div`
	padding: 80px 16px;
	${media.md`
		padding: 120px 24px;
	`}
	${media.xl`
		padding: 160px 80px;
	`}
	${media.xxl`
		padding: 160px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Container = styled.div`
	background: ${(p) => p.theme.colors.bluePrimary};
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	${media.md`
        align-items: start;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 30px;
    `}
	${media.lg`
        gap: 40px;
        grid-template-columns: repeat(3, 1fr);
	`}
`;

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	position: relative;
	img {
		width: 100%;
	}
`;

const FirstTruck = styled.div``;

const MiddleTruck = styled.div``;

const LastTruck = styled.div``;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.white};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
        line-height: 22px;
    `}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
        line-height: 24px;
    `}
`;

const Arrow = styled.div`
	height: 40px;
	${media.md`
        display: none;
    `}
	img {
		width: auto;
		margin: 0 auto;
	}
	${media.md`
	    &[data-last="true"] {
			position: absolute;
            display: block;
            top: -56px;
            left: 50%;
			transform: translate(-50%);
	    }
    `}
	${media.lg`
        &[data-last="true"] {
			display: none;
	    }
    `}
`;

const Title = styled.div`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	text-align: center;
	margin-bottom: 24px;
	line-height: 32px;
	color: ${(p) => p.theme.colors.white};
	letter-spacing: 0.01em;
	${media.md`
        margin-bottom: 60px;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
        line-height: 48px;
    `}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h1};
				`}
        line-height: 88px;
    `}
`;

const TabletSection = styled.div`
	display: none;
	${media.md`
       display: block;
    `}
	${media.lg`
        display: none;
    `}
`;

const Partner = styled.div`
	> img {
		width: 100%;
		padding: 275px 0;
		${media.md`
			width: 580px;
			padding: 360px 0;
			margin: 0 auto;
    	`}
	}
`;

const SubTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	margin-bottom: 32px;
	line-height: 32px;
	color: ${(p) => p.theme.colors.white};
	letter-spacing: 0.01em;
	${media.md`
		margin-bottom: -16px;
		width: 78%;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
        line-height: 40px;
    `}
	${media.xl`
		width: 48%;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
        line-height: 48px;
		position: absolute;
		top: 210px;
		left: 0;
    `}
	${media.xxl`
		width: 40%;
	`}
`;

const TruckWrapper = styled.div`
	min-width: 115vw;
	${media.xl`
		min-width: 105vw;
	`}
	${media.xxl`
		min-width: auto;
	`}
`;

const Technologies = styled.div`
	${media.xl`
		position: relative;
	`}
`;
