import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const VolvoRequirements = ({ data, images }) => {
	return (
		<Wrapper>
			<Header>
				<Title>{data.title}</Title>
				<TextWrapper>
					<SubTitle>{data.subtitle}</SubTitle>
					<Description>{data.description}</Description>
				</TextWrapper>
			</Header>
			<Rows>
				{data.content_rows.map((row, index) => (
					<Row key={row.title} data-left={(index + 1) % 2 === 0 ? 'true' : 'false'}>
						<ImageWrapper>
							<SkImage {...images.find((image) => image.name === row.image).image} />
						</ImageWrapper>
						<Content>
							<RowTitle>{row.title}</RowTitle>
							<RowText>{row.sub_text}</RowText>
						</Content>
					</Row>
				))}
			</Rows>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 80px 16px 60px 16px;
	${media.md`
		padding: 120px 24px;
	`}
	${media.xl`
		padding: 160px 80px;
	`}
	${media.xxl`
		padding: 160px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Rows = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		gap: 40px;
	`}
	${media.xl`
		gap: 60px;
	`}
`;

const Header = styled.div`
	margin-bottom: 24px;
	${media.md`
        margin-bottom: 40px;
    `}
	${media.xl`
        margin-bottom: 60px;
        display: flex;
        gap: 40px;
        flex-direction: row;
        align-items: end;
    `}
`;

const Row = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.xl`
        flex-direction: row;
        gap: 40px;
	`}
	&[data-left='true'] {
		${media.xl`
            flex-direction: row-reverse;
        `}
	}
`;

const ImageWrapper = styled.div`
	> img {
		width: 100%;
	}
	${media.xl`
        width: 50%;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;
	${media.xl`
        width: 50%;
	`}
`;

const RowTitle = styled.div`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.bluePrimary};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
                line-height: 32px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
                line-height: 40px;
	`}
`;

const RowText = styled.div`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.dark};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
                line-height: 22px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
                line-height: 24px;
	`}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        margin-left: 33.75%;
    `}
	${media.xl`
        max-width: 40%;
        gap: 24px;
        margin-left: 0;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.bluePrimary};
	letter-spacing: 0.01em;
	margin-bottom: 24px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
                line-height:48px;
	`}
	${media.xl`
        margin-bottom: 0;
        width: 45%;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
                line-height: 72px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.dark};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
                line-height: 24px;
	`}
	${media.xl`
        font-weight: 600;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t5};
				`}
                line-height: 28px;
	`}
`;

const Description = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.dark};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
                line-height: 22px;
	`}
`;
