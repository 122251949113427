import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';
import { RemixIcon } from '../shared/remix-icon';

export const VolvoShweden = ({ data, images }) => {
	return (
		<Wrapper id="link">
			<Header>
				<MainTitle>{data.title}</MainTitle>
				<SubTitle>{data.subtitle}</SubTitle>
			</Header>
			<Items>
				{data.items.map((item) => (
					<Item key={item.title}>
						<Icon>
							<RemixIcon className={item.icon} />
						</Icon>
						<Title>{item.title}</Title>
						<Text>{item.text}</Text>
					</Item>
				))}
			</Items>
			<Images>
				<Image>
					<SkImage {...images.find((image) => image.name === data.image_1).image} />
				</Image>
				<Image>
					<SkImage {...images.find((image) => image.name === data.image_2).image} />
				</Image>
			</Images>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 16px;
	gap: 40px;
	${media.md`
		padding: 60px 24px;
	`}
	${media.xl`
		padding: 80px 80px;
        gap: 60px;
	`}
	${media.xxl`
		padding: 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		flex-direction: row;
        gap: 54px;
	`}
	${media.xl`
        gap: 145px;
	`}
`;

const MainTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	width: 100%;
	color: ${(p) => p.theme.colors.bluePrimary};
	line-height: 32px;
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	width: 100%;
	color: ${(p) => p.theme.colors.headerText};
	line-height: 22px;
	${media.md`
        display: flex;
        align-items: flex-end;
        width: 20%;
        justify-content: end;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	${media.md`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    `}
	${media.xl`
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 60px; 
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Title = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	color: ${(p) => p.theme.colors.dark};
	line-height: 24px;
	min-height: 65px;
	position: relative;
	${media.md`
        font-weight: 600;
	    ${(p) => css`
				font-size: ${p.theme.typography.sizes.t5};
			`}
	    line-height: 28px;
	    min-height: 73px;
	`}
	&::after {
		content: ' ';
		position: absolute;
		width: 100%;
		height: 1px;
		bottom: 0;
		left: 0;
		background: ${(p) => p.theme.colors.superlightGray};
	}
`;

const Icon = styled.div`
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
	color: ${(p) => p.theme.colors.bluePrimary};
	margin-bottom: 3px;
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	color: ${(p) => p.theme.colors.dark};
	line-height: 20px;
	${media.md`
	    ${(p) => css`
				font-size: ${p.theme.typography.sizes.t3};
			`}
	    line-height: 22px;
	`}
`;

const Images = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	${media.xl`
        flex-direction: row;
    `}
`;

const Image = styled.div`
	height: 400px;
	width: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	> img {
		height: 100%;
		max-width: fit-content;
	}
	${media.md`
        height: auto;
        display: block;
        > img {
		    max-width: 100%;
	    }
    `}
`;
