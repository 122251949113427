import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../styles/mixins';
import { RemixIcon } from '../shared/remix-icon';

export const VolvoMetrics = ({ data }) => {
	return (
		<Wrapper>
			<Items>
				{data.items.map((item) => (
					<Item key={item.title}>
						<IconWrapper>
							<RemixIcon className={item.icon} />
						</IconWrapper>
						<Title>{item.title}</Title>
						<Description>{item.description}</Description>
					</Item>
				))}
			</Items>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 80px 16px 40px 16px;
	${media.md`
		padding: 120px 24px 60px 24px;
	`}
	${media.xl`
		padding: 80px 80px;
	`}
	${media.xxl`
		padding: 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
    z-index: 5;
	position: relative;
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		gap: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
	`}
	${media.xl`
		display: flex;
        flex-direction: row;
	`}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
	${transition('all')};
	${media.md`
		padding: 20px;
        box-sizing: border-box;
        ${(p) => css`
					&:hover {
						background: ${(p) => p.theme.colors.superlightGray};
						> div {
							&:first-child {
								background: ${(p) => p.theme.colors.white};
							}
						}
					}
				`}
	`}
	${media.xl`
       gap: 8px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
	line-height: 28px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.bluePrimary};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
	    line-height: 40px;
	`}
`;

const Description = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
	`}
`;

const IconWrapper = styled.div`
	background: ${(p) => p.theme.colors.superlightGray};
	color: ${(p) => p.theme.colors.bluePrimary};
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
	${media.md`
        width: 48px;
        height: 48px;
        margin-bottom: 12px;
    `}
    ${media.xl`
        margin-bottom: 8px;
    `}
`;
