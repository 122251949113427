import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { Link } from '../shared/link';

export const VolvoWhyUs = ({ data, path }) => {
	return (
		<Wrapper>
			<Content>
				<Title
					dangerouslySetInnerHTML={{
						__html: data.title.replaceAll('Softkit', '<span>Softkit</span>'),
					}}
				/>
				<Description
					dangerouslySetInnerHTML={{
						__html: data.description.replaceAll('Softkit', '<span>Softkit</span>'),
					}}
				/>
				<LinkWrapper>
					<Link label={data.link.label} remixIcon={data.link.icon} url={path + data.link.url} />
				</LinkWrapper>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 40px 16px 80px 16px;
	${media.md`
		padding: 60px 24px 120px 24px;
	`}
	${media.xl`
		padding: 80px 80px 160px 80px;
	`}
	${media.xxl`
		padding: 80px 0 160px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Content = styled.div`
	padding: 40px 16px;
	background: ${(p) => p.theme.colors.superlightGray};
	gap: 16px;
	display: flex;
	flex-direction: column;
	${media.md`
		padding: 60px 40px;
        gap: 24px;
	`}
	${media.xl`
        position: relative;
		padding: 98px;
        gap: 40px;
        justify-content: space-between;
        flex-direction: row;
	`}
    ${media.xxl`
		padding: 98px 135px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	text-align: center;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.headerText};
	> span {
		color: ${(p) => p.theme.colors.orange1};
	}
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
	    line-height: 32px;
        text-align: left;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
	    line-height: 40px;
        width: 50%;
	`}
`;

const Description = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	text-align: center;
	color: ${(p) => p.theme.colors.dark};
	> span {
		color: ${(p) => p.theme.colors.orange1};
	}
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
        text-align: left;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t5};
				`}
	    line-height: 28px;
        width: 50%;
	`}
`;

const LinkWrapper = styled.div`
	text-align: center;
	> a {
		background: ${(p) => p.theme.colors.dark};
		font-weight: 400;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		line-height:22px;
		&:hover {
			background: ${(p) => p.theme.colors.bluePrimary};
			gap: 8px;
		}
		${media.md`
            ${(p) => css`
							font-size: ${p.theme.typography.sizes.t4};
						`}
                        line-height: 24px;
        `}
		${media.xl`
            ${(p) => css`
							font-size: ${p.theme.typography.sizes.t5};
						`}
                        line-height: 28px;
                        position: absolute;
                        left: 98px;
                        bottom: 98px;
        `}
        ${media.xxl`
            left: 135px;
            bottom: 98px;
        `}
	}
	${media.md`
        text-align: left;
	`}
`;
