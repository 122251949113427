import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../styles/mixins';
import { RemixIcon } from '../shared/remix-icon';

export const VolvoFeatures = ({ data }) => {
	return (
		<Wrapper>
			<MainTitle>{data.title}</MainTitle>
			<Items>
				{data.items.map((item) => (
					<Item key={item.title}>
						<Title>{item.title}</Title>
						<SubTitle>{item.subtitle}</SubTitle>
						<Text>{item.text}</Text>
						<Icon>
							<RemixIcon className={item.icon} />
						</Icon>
					</Item>
				))}
			</Items>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 40px 16px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		padding: 60px 24px;
		gap: 40px;
	`}
	${media.xl`
		padding: 80px 80px;
		gap: 60px;
	`}
	${media.xxl`
		padding: 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const MainTitle = styled.div`
	width: 100%;
	text-align: center;
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	color: ${(p) => p.theme.colors.bluePrimary};
	line-height: 32px;
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
		width: 720px;
		margin: 0 auto;
	`}
`;

const Items = styled.div`
	background: ${(p) => p.theme.colors.superlightGray};
	display: flex;
	flex-direction: column;
	gap: 1px;
	${media.md`
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	`}
	${media.xl`
		grid-template-columns: repeat(4, 1fr);
	`}

	> div:first-child {
		margin-top: 1px;
		${media.md`
			margin-top: 0;
		`}
	}
	> div:last-child {
		margin-bottom: 1px;
		${media.md`
			margin-bottom: 0;
		`}
	}
`;

const Item = styled.div`
	background: ${(p) => p.theme.colors.white};
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 24px 0;
	${media.md`
		padding: 24px;
		gap: 16px;
	`}
	${transition('all')};
	&:hover {
		background: ${(p) => p.theme.colors.superlightGray};
	}
`;

const Title = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	color: ${(p) => p.theme.colors.bluePrimary};
	line-height: 24px;
	${media.md`
		font-weight: 600;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	color: ${(p) => p.theme.colors.dark};
	line-height: 22px;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	color: ${(p) => p.theme.colors.dark};
	line-height: 20px;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;

const Icon = styled.div`
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
	color: ${(p) => p.theme.colors.bluePrimary};
	height: 100%;
	align-items: flex-end;
	display: flex;
`;
