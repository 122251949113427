import React from 'react';
import styled, { css } from 'styled-components';

import { Breadcrumbs } from '../../components/breadcrumbs';
import { media, transition } from '../../styles/mixins';
import { SkImage } from '../shared/image';
import { Link } from '../shared/link';

export const VolvoHero = ({ data, images, path }) => {
	return (
		<Container>
			<Breadcrumbs
				isWrapped
				slug={path || '/case-studies/all-in-one-whitelabel-app-store-platform'}
			/>
			<Wrapper>
				<TextWrapper>
					<Title>{data.title}</Title>
					<TagsWrapper>
						{data.tags.map((tag) => (
							<Tag key={tag}>{tag}</Tag>
						))}
					</TagsWrapper>
					<Description>{data.description}</Description>
					<LinkWrapper>
						<Link label={data.link.label} remixIcon={data.link.icon} url={data.link.url} />
					</LinkWrapper>
				</TextWrapper>
				<ImageWrapper>
					<SkImage {...images.find((image) => image.name === data.side_image).image} />
				</ImageWrapper>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	z-index: 5;
	margin: 24px 0 81px 0;
	${media.md`
		margin: 12px 0 130px 0;
	`}
	${media.xl`
		padding: 0;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	padding: 0 16px 0 16px;
	${media.md`
		padding: 0 24px;
	`}
	${media.xl`
		padding: 0 80px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;
		height: 450px;
		margin-bottom: 16px;
	`}
	${media.xxl`
		padding: 0;
		margin: 0 auto;
		max-width: 1460px;
		height: 540px;
	`}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;
	${media.xl`
		gap: 24px;
	`}
`;

const ImageWrapper = styled.div`
	margin-right: -30px;
	> img {
		width: 100%;
	}
	${media.xl`
		margin-right: 0;
		> img{
			width: auto;
			height: 450px;
		}
		position: absolute;
		right: -50px;
		bottom: 0;
	`}
	${media.xxl`
		> img {
			height: 540px;
		}
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
	`}
`;

const Description = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const LinkWrapper = styled.div`
	> a {
		background: ${(p) => p.theme.colors.gray6};
		font-weight: 500;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		line-height:22px;
		&:hover {
			background: ${(p) => p.theme.colors.bluePrimary};
			gap: 8px;
		}
	}
`;

const TagsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex-wrap: wrap;
`;

const Tag = styled.div`
	padding: 6px 12px;
	color: ${(p) => p.theme.colors.gray};
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	background: ${(p) => p.theme.colors.superlightGray};
	${transition('all')};
	&:hover {
		background: ${(p) => p.theme.colors.bluePrimary};
		color: ${(p) => p.theme.colors.white};
	}
	border-radius: 40px;
	${media.md`
		padding: 7 16px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;
