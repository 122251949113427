import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const VolvoTarget = ({ data, images }) => {
	return (
		<Wrapper>
			<Image>
				<SkImage {...images.find((image) => image.name === data.image).image} />
			</Image>
			<Content>
				<Title>{data.title}</Title>
				<Text>{data.text}</Text>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 80px 16px 40px 16px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		padding: 120px 24px 60px 24px;
        gap: 40px;
	`}
	${media.xl`
		padding: 160px 80px 80px 80px;
	`}
	${media.xxl`
		padding: 160px 0 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Image = styled.div`
	width: 100%;
	height: fit-content;
	> img {
		width: 100%;
		height: auto;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
        gap: 16px;
	`}
	${media.xl`
        gap: 40px;
        flex-direction: row;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	line-height: 24px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	width: 100%;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h5};
				`}
	    line-height: 28px;
	`}
	${media.xl`
        width: 50%;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.dark};
	width: 100%;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	${media.xl`
        width: 50%;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;
