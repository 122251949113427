import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { RemixIcon } from '../shared/remix-icon';

export const VolvoPartner = ({ data }) => {
	return (
		<Container>
			<Wrapper>
				<Items>
					{data.items.map((item) => (
						<Item key={item.title}>
							<Icon>
								<RemixIcon className={item.icon} />
							</Icon>
							<Header>
								<Title>{item.title}</Title>
								<SubTitle>{item.subtitle}</SubTitle>
							</Header>
							<Text>{item.text}</Text>
						</Item>
					))}
				</Items>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	margin: 40px 16px 80px 16px;
	${media.md`
		margin: 60px 24px 120px 24px;
	`}
	${media.xl`
		margin: 80px 40px 160px 40px;
	`}
	background: ${(p) => p.theme.colors.superlightGray};
`;

const Wrapper = styled.div`
	padding: 40px 16px;
	${media.md`
		padding: 60px 24px;
	`}
	${media.xl`
        padding: 98px 40px;
	`}
    ${media.xxl`
		padding: 98px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		display: grid;
        grid-template-columns: repeat(3, 1fr);
	`}
	${media.xl`
        gap: 40px;
	`}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Icon = styled.div`
	color: ${(p) => p.theme.colors.bluePrimary};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t6};
		`}
		line-height: 40px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	display: flex;
	align-items: center;
	margin-top: 5px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
        margin-top: 0;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	color: ${(p) => p.theme.colors.dark};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t2};
		`}
		line-height: 20px;
	`}
`;
