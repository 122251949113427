import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';
import { RemixIcon } from '../shared/remix-icon';

export const VolvoAbout = ({ data, images }) => {
	return (
		<Wrapper>
			<Title>{data.title}</Title>
			<Content>
				<PartnerWrapper>
					<SkImage {...images.find((image) => image.name === data.partner).image} />
				</PartnerWrapper>
				<ImageWrapper>
					<SkImage {...images.find((image) => image.name === data.trucks).image} />
				</ImageWrapper>
				<Text>{data.text}</Text>
				<Items>
					{data.items.map((item) => (
						<Item key={item.title}>
							<IconWrapper>
								<RemixIcon className={item.icon} />
							</IconWrapper>
							<ItemContent>
								<ItemTitle>{item.title}</ItemTitle>
								<Description>{item.description}</Description>
							</ItemContent>
						</Item>
					))}
				</Items>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 40px 16px;
	${media.md`
		padding: 60px 24px;
	`}
	${media.xl`
		padding: 80px 80px;
	`}
	${media.xxl`
		padding: 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

const ItemContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Title = styled.p`
	color: ${(p) => p.theme.colors.bluePrimary};
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	letter-spacing: 0.01em;
	margin-bottom: 24px;
	text-align: center;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
                line-height: 48px;
                margin-bottom: 60px;
    `}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h1};
				`}
                line-height: 88px;
    `}
`;

const ItemTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
                line-height: 22px;
    `}
	color: ${(p) => p.theme.colors.gray6};
`;

const ImageWrapper = styled.div`
	width: 100%;
	margin-bottom: 24px;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
        max-width: 515px;
        margin: 0 auto 40px auto;
    `}
	${media.xl`
        max-width: 700px;
    `}
`;

const PartnerWrapper = styled.div`
	width: 100%;
	position: absolute;
	left: 0;
	top: -10px;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
        top: -36px;
    `}
	${media.xl`
        max-width: 1165px;
        left: 50%;
        transform: translateX(-50%);
    `}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	text-align: center;
	margin-bottom: 24px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
                line-height: 24px;
                margin-bottom: 40px;
                width: 100%;
    `}
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        max-width: 970px;
        margin: 0 auto 40px auto;
    `}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        flex-direction: row;
        padding: 45px;
    `}
	${media.xl`
        max-width: 635px;
        margin: 0 auto;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	height: 48px;
	width: 100%;
`;

const Description = styled.p`
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.gray6};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
                line-height: 24px;
    `}
	color: ${(p) => p.theme.colors.headerText};
`;

const IconWrapper = styled.div`
	background: ${(p) => p.theme.colors.superlightGray};
	color: ${(p) => p.theme.colors.bluePrimary};
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
`;
