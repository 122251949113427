import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const VolvoOffice = ({ data, images }) => {
	return (
		<Wrapper>
			<SkImage {...images.find((image) => image.name === data.image).image} />
			<Content>
				<Title>{data.title}</Title>
				<TextWrapper>
					<SubTitle>{data.subtitle}</SubTitle>
					<Text>{data.text}</Text>
				</TextWrapper>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 16px;
	gap: 24px;
	${media.md`
		padding: 60px 24px;
        gap: 40px;
	`}
	${media.xl`
		padding: 80px 80px;
	`}
	${media.xxl`
		padding: 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
    > img {
		width: 100%;
		height: auto;
	}
`;

const Content = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: column;
	${media.md`
        gap: 24px;
	`}
	${media.xl`
        flex-direction: row;
		gap: 40px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	width: 100%;
	color: ${(p) => p.theme.colors.bluePrimary};
	line-height: 28px;
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
	${media.xl`
        width: 50%;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t6};
		`}
		line-height: 40px;
	`}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
	${media.md`
        gap: 16px;
	`}
	${media.xl`
        width: 50%;
		gap: 24px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	line-height: 24px;
	color: ${(p) => p.theme.colors.dark};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.dark};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;
